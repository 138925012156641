import { bech32m } from 'bech32';
/* eslint-disable import/default */
import chiaBls from 'chia-bls';
import clvmLib from 'clvm-lib';
import chiaRpc from 'chia-rpc';
/* eslint-enable import/default */
import { STANDARD_PUZZLE_MOD } from '~/utilities/puzzles';

/* eslint-disable import/no-named-as-default-member */
export const { fromHex, toHex, hash256, concatBytes, JacobianPoint } = chiaBls;
export const { Program } = clvmLib;
export const { toCoinId } = chiaRpc;
/* eslint-enable import/no-named-as-default-member */

declare global {
  interface Window {
    chia: any;
    goby: any;
  }
}
type Amount = string | number;
type HexString = string;

interface Coin {
  parent_coin_info: string;
  puzzle_hash: string;
  amount: number;
}

interface CoinSpend {
  coin: Coin;
  puzzle_reveal: HexString;
  solution: HexString;
}

export interface SpendBundle {
  coin_spends: CoinSpend[];
  aggregated_signature: HexString;
}

interface TakeOfferParams {
  offer: string;
}

interface AssetAmount {
  assetId: string;
  amount: Amount;
}

interface TransferParams {
  to: string;
  amount: Amount;
  assetId: string;
  memos?: HexString[];
}

export interface CreateOfferParams {
  offerAssets: AssetAmount[];
  requestAssets: AssetAmount[];
}

interface AssetBalanceResp {
  confirmed: string;
  spendable: string;
  spendableCoinCount: number;
}

interface getAssetCoinsParams {
  type: string | null;
  assetId?: string | null;
  includedLocked?: boolean;
  offset?: number;
  limit?: number;
}

export interface SpendableCoin {
  coin: Coin;
  coinName: string;
  puzzle: string;
  confirmedBlockIndex: number;
  locked: boolean;
  lineageProof?: {
    parentName?: string;
    innerPuzzleHash?: string;
    amount?: number;
  };
}

export interface MetadataInfo {
  dataUris: string[];
  dataHash: string;
  metadataUris: string[];
  metadataHash: string;
  licenseUris: string[];
  licenseHash: string;
  editionTotal: number;
  editionNumber: number;
}

interface MintNFTParams {
  metadataInfo: MetadataInfo;
  didId: string;
  royaltyAddress?: string;
  royaltyPercentage?: number;
}

interface sendTransactionParams {
  spendBundle: SpendBundle;
}

// stay the same as [transaction_ack](https://docs.chia.net/docs/10protocol/wallet_protocol/#transaction_ack)
enum MempoolInclusionStatus {
  SUCCESS = 1, // Transaction added to mempool
  PENDING = 2, // Transaction not yet added to mempool
  FAILED = 3, // Transaction was invalid and dropped
}

interface TransactionResp {
  status: MempoolInclusionStatus;
  error?: string;
}

interface SignMessageParams {
  message: string;
  publicKey: string;
}

export interface Wallet {
  chainId(): Promise<string>;

  accounts(): Promise<string[]>;

  filterUnlockedCoins(params: { coinNames: string[] }): Promise<string[]>;

  walletSwitchChain(params: { chainId: string }): Promise<null>;

  connect(params?: { eager?: boolean }): Promise<boolean>;

  transfer(params: TransferParams): Promise<{
    id: string;
  }>; // @deprecated
  takeOffer(params: TakeOfferParams): Promise<{
    transaction_id: string;
    transaction: SpendBundle;
  }>; // @deprecated
  createOffer(params: CreateOfferParams): Promise<{
    transaction_id: string;
    offer: string;
  }>; // @deprecated
  getPublicKeys(params?: { limit?: number; offset?: number }): Promise<string[]>;

  signCoinSpends(params: { coinSpends: CoinSpend[] }): Promise<string>;

  estimateCoinSpendsFee(params: { coinSpends: CoinSpend[] }): Promise<{ estimates: [number, number, number] }>;

  getAssetBalance(params: { type: string | null; assetId: string | null }): Promise<AssetBalanceResp>;

  getAssetCoins(params: getAssetCoinsParams): Promise<SpendableCoin[]>;

  sendTransaction(params: sendTransactionParams): Promise<TransactionResp[]>;

  signMessage(params: SignMessageParams): Promise<string>;

  mintNFT(params: MintNFTParams): Promise<SpendBundle>;
}

export const wallet = new Proxy({} as Wallet, {
  get(_, key) {
    return function (params: any) {
      return window.chia.request({ method: key, params });
    };
  },
});

const isGobyInstalled = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const { chia } = window;
  return Boolean(chia && chia.isGoby);
};

const requireGoby = <T>(fn: () => T): T => {
  if (!isGobyInstalled()) {
    throw new Error('Goby is not installed');
  }
  return fn();
};

export const connect = (eager = false) => {
  return wallet.connect({ eager });
};

export const getPublicKeys = () => {
  return wallet.getPublicKeys();
};

export const getP2Puzzle = async () => {
  const publicKeys = await getPublicKeys();
  const key = JacobianPoint.fromHex(publicKeys[0].replace('0x', ''), false);
  return STANDARD_PUZZLE_MOD.curry([Program.fromJacobianPoint(key)]);
};

export const getAssetCoins = (type: 'did' | 'nft' | null, assetId = null, includedLocked = false) => {
  const params = { type, assetId, includedLocked, limit: 10, offset: 0 };
  return wallet.getAssetCoins(params);
};

export const mintNft = (
  metadataInfo: MetadataInfo,
  didId: string,
  royaltyAddress?: string,
  royaltyPercentage?: number
) => {
  return wallet.mintNFT({
    metadataInfo,
    didId,
    royaltyAddress,
    royaltyPercentage,
  });
};

export const transfer = (to: string, amount: number, assetId = '') => {
  try {
    return wallet.transfer({ to, amount, assetId });
  } catch (e) {}
};

export const createOffer = (offer: CreateOfferParams) => {
  return wallet.createOffer(offer);
};

export const takeOffer = (offer: string) => {
  try {
    return wallet.takeOffer({ offer });
  } catch (e) {}
};

export const signCoinSpends = (coinSpends: CoinSpend[]) => {
  return wallet.signCoinSpends({ coinSpends });
};
export const estimateCoinSpendsFee = (coinSpends: CoinSpend[]) => {
  return wallet.estimateCoinSpendsFee({ coinSpends });
};

export const signMessage = async (message: string) => {
  const publicKeys = await getPublicKeys();
  const publicKey = publicKeys[0];
  const signature = await wallet.signMessage({ message, publicKey });
  return { publicKey, signature };
};

export const sendTransaction = (spendBundle: SpendBundle) => {
  try {
    return wallet.sendTransaction({ spendBundle });
  } catch (e) {}
};

export const useGoby = () => {
  const goby = reactive<{ account: string; isGobyInstalled?: boolean }>({
    account: '',
    isGobyInstalled: undefined,
  });

  function setAccount() {
    wallet.accounts().then((accounts: string[]) => {
      const account = accounts?.[0];
      goby.account = account ? bech32m.encode('xch', bech32m.toWords(fromHex(account))) : '';
    });
  }

  if (!goby.isGobyInstalled && isGobyInstalled()) {
    goby.isGobyInstalled = true;

    window.chia.on('accountChanged', () => setAccount());
    window.chia.on('chainChanged', () => window.location.reload());

    setAccount();
  } else {
    goby.isGobyInstalled = false;
  }

  return {
    goby,
    connect: (eager = false) => requireGoby(() => connect(eager)),
    getPublicKeys: () => requireGoby(() => getPublicKeys()),
    getP2Puzzle: () => requireGoby(() => getP2Puzzle()),
    getAssetCoins: (type: 'did' | 'nft' | null = null, assetId = null, includedLocked = false) =>
      requireGoby(() => getAssetCoins(type, assetId, includedLocked)),
    transfer: (to: string, amount: number, assetId = '') => requireGoby(() => transfer(to, amount, assetId)),
    createOffer: (offer: CreateOfferParams) => requireGoby(() => createOffer(offer)),
    takeOffer: (offer: string) => requireGoby(() => takeOffer(offer)),
    signCoinSpends: (coinSpends: CoinSpend[]) => requireGoby(() => signCoinSpends(coinSpends)),
    estimateCoinSpendsFee: (coinSpends: CoinSpend[]) => requireGoby(() => estimateCoinSpendsFee(coinSpends)),
    signMessage: (message: string) => requireGoby(() => signMessage(message)),
    sendTransaction: (spendBundle: SpendBundle) => requireGoby(() => sendTransaction(spendBundle)),
    mintNft: (metadataInfo: MetadataInfo, didId: string, royaltyAddress?: string, royaltyPercentage?: number) =>
      requireGoby(() => mintNft(metadataInfo, didId, royaltyAddress, royaltyPercentage)),
  };
};
