// eslint-disable-next-line import/default
import clvmLib from 'clvm-lib';
import didInnerpuzHex from '~/utilities/puzzles/did_innerpuz.clsp.hex?raw';
import p2DelegatedPuzzleOrHiddenPuzzleHex from '~/utilities/puzzles/p2_delegated_puzzle_or_hidden_puzzle.clsp.hex?raw';
import singletonLauncherHex from '~/utilities/puzzles/singleton_launcher.clsp.hex?raw';
import singletonTopLayerHex from '~/utilities/puzzles/singleton_top_layer_v1_1.clsp.hex?raw';
import p2ConditionsHex from '~/utilities/puzzles/p2_conditions.clsp.hex?raw';

// eslint-disable-next-line import/no-named-as-default-member
const { Program } = clvmLib;

export const STANDARD_PUZZLE_MOD = Program.deserializeHex(p2DelegatedPuzzleOrHiddenPuzzleHex);
export const SINGLETON_LAUNCHER_MOD = Program.deserializeHex(singletonLauncherHex);
export const SINGLETON_TOP_LAYER_MOD = Program.deserializeHex(singletonTopLayerHex);
export const DID_INNERPUZ_MOD = Program.deserializeHex(didInnerpuzHex);
export const P2_CONDITIONS_MOD = Program.deserializeHex(p2ConditionsHex);
